<template>
  <div class="404-page h-100 content-center">

    <section>
      <div class="container py-5 text-center">
        <h1 class="text-light display-3">{{ this.$t('arr_blocked_account') }} # {{ this.arr_id }}</h1>
        <p class="text-muted">{{ this.$t('arr_blocked_desc') }}</p>
        <!-- <router-link to="/" class="btn">Go Home</router-link> -->
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'Invoice',
    props: ['arr_id'],
    methods: {
      
    },
    mounted () {
      this.$navbar.name = `Arr #${ this.arr_id }`;
    }
  }
</script>
