var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "404-page h-100 content-center" }, [
    _c("section", [
      _c("div", { staticClass: "container py-5 text-center" }, [
        _c("h1", { staticClass: "text-light display-3" }, [
          _vm._v(
            _vm._s(this.$t("arr_blocked_account")) + " # " + _vm._s(this.arr_id)
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-muted" }, [
          _vm._v(_vm._s(this.$t("arr_blocked_desc")))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }